import { Button } from '@nextui-org/react';
import { Metadata } from 'next';
import Link from 'next/link';
import * as React from 'react';
import { RiAlarmWarningFill } from 'react-icons/ri';

export const metadata: Metadata = {
  title: 'Not Found',
};

export default function NotFound() {
  return (
    <main>
      <section className="bg-white">
        <div className="layout flex min-h-screen flex-col items-center justify-center text-center text-black">
          <RiAlarmWarningFill size={60} className="drop-shadow-glow animate-flicker text-red-500" />
          <h1 className="mt-8 text-4xl md:text-6xl">Page Not Found</h1>
          <Button
            color="primary"
            className="mt-8 h-12 rounded text-white"
            disableRipple
            disableAnimation
          >
            <Link href="/">Back to home</Link>
          </Button>
        </div>
      </section>
    </main>
  );
}
